import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { Contact } from 'src/app/_models/contact';
import { ContactEvent } from 'src/app/_models/contact-event';
import { Event } from 'src/app/_models/event';
import { DialogData } from '../event-calendar/event-calendar.component';
import { EventService } from 'src/app/_services/event.service';
import * as _ from 'lodash';
import * as dayjs from 'dayjs';
import { forkJoin, map, Observable, of, switchMap, take } from 'rxjs';
import { ContactService } from 'src/app/_services/contact.service';
import { UserService } from 'src/app/_services/user.service';
import { EventVolunteerOpportunity } from 'src/app/_models/event-volunteer-opportunity';

@Component({
    templateUrl: './event-registration.component.html',
    styleUrls: ['./event-registration.component.scss']
})
export class EventRegistrationComponent implements OnInit {
    event: Event;
    contact: Contact;
    opportunities: EventVolunteerOpportunity[] = [];
    
    contactEvent: ContactEvent = {} as ContactEvent;

    isLoading: boolean = false;
    basicInfoComplete: boolean = false;
    childSelectionComplete: boolean = false;
    currentStep: string;
    isAlreadyRegistered: boolean = false;
    isFormValid: boolean = true;

    constructor(private eventService: EventService, private contactService: ContactService, public dialogRef: DialogRef<ContactEvent>, @Inject(DIALOG_DATA) 
        public data: DialogData, private userService: UserService) { }

    ngOnInit(): void {
        this.event = this.data.event;
        this.contactEvent.event = this.event;
        this.contact = this.data.contact;
        this.contactEvent.contact = this.contact;

        this.contactEvent = {
            event: this.event,
            eventGuid: this.event?.guid,
            contact: this.contact,
            contactGuid: this.contact?.guid
        } as ContactEvent;

        if (!!this.event && !!this.contact) {
            this.currentStep = 'contact-basic-info';

            if (this.contactService.isBasicInfoComplete(this.contactEvent?.contact)) {
                this.currentStep = this.getLastStep();
            }
        }
        else {
            this.currentStep = 'contact-selection';
        }

        if (!!this.event && !!this.contact) {
            this.eventService.getContactEvent(this.event.guid, this.contact.guid).pipe(take(1)).subscribe(res => {
                if (!!res) {
                    this.contactEvent = res;

                    // Copy these so we don't lose the child.isSelected
                    this.contactEvent.contact = _.cloneDeep(this.contact);
                    this.contactEvent.event = _.cloneDeep(this.event);
                    
                    this.isAlreadyRegistered = true;
                }

                this.updateChildSelectionStatus();
            })
        }

        if (!!this.event) {
            this.eventService.getEventVolunteerOpportunities(this.event.guid).pipe(take(1)).subscribe(res => {
                this.opportunities = res;

                if (!!this.contact) {
                    _.each(this.opportunities, o => {
                        o.isSelected = o.assignedVolunteerGuid == this.contact.guid;
                    });
                }
            });
        }
    }

    getLastStep() {
        return this.isVolunteer() ? 'opportunity-selection' : 'child-selection';
    }

    isClient() {
        return !this.contactEvent.contact?.roles || this.contactEvent.contact.roles.length == 0 || _.some(this.contactEvent.contact.roles, r => r.name == 'Client');
    }

    isVolunteer() {
        return !!this.contactEvent.contact?.roles && this.contactEvent.contact.roles.length > 0 && _.some(this.contactEvent.contact.roles, r => r.name == 'Volunteer');
    }

    updateChildSelectionStatus() {
console.log(this.contactEvent.contact);        
console.log(this.isClient());        
        if (this.contactEvent.contact?.children?.length > 0 && !this.event.isParentsOnly && this.isClient()) {
            if (this.isAlreadyRegistered) {
                _.each(this.contactEvent.contact.children, child => {
                    this.eventService.getChildContactEvent(this.event.guid, this.contactEvent.contact.guid, child.guid).pipe(take(1)).subscribe(res => {
                        child.isSelected = !!res;
                    });
                });
            }
            else {
                _.each(this.contactEvent.contact.children, child => { child.isSelected = true; });
            }
        } 
    }

    onSave() {
        this.dialogRef.close(this.contactEvent);
    }

    onCancel() {
        this.dialogRef.close();
    }

    onEditContact() {
        this.currentStep = 'contact-basic-info';
    }

    isNewContact() {
        return !this.contactEvent.contact?.guid || this.contactEvent.contact?.guid.length == 0;
    }

    onNextClick() {
        if (this.currentStep == 'contact-selection') {
            this.userService.setUser(this.contactEvent.contact);

            if (this.contactService.isBasicInfoComplete(this.contactEvent?.contact)) {
                this.currentStep = this.getLastStep();
                this.updateChildSelectionStatus();
            }
            else {
                this.currentStep = 'contact-basic-info';
            }
        }
        else if (this.currentStep == 'contact-basic-info') {
            if (this.isNewContact()) {
                this.contactService.addContact(this.contactEvent.contact).pipe(take(1)).subscribe(res => {
                    this.contactEvent.contact = res;
                    this.userService.setUser(this.contactEvent.contact);

                    this.currentStep = this.getLastStep();
                    this.updateChildSelectionStatus();
                })
            }
            else {
                this.contactService.updateContact(this.contactEvent.contact).pipe(take(1)).subscribe(res => {
                    this.contactEvent.contact = res;
                    this.userService.setUser(this.contactEvent.contact);

                    this.currentStep = this.getLastStep();
                    this.updateChildSelectionStatus();
                })
            }
        }
        else if (this.currentStep == this.getLastStep()) {
            this.saveData().pipe(take(1)).subscribe(res => {
                this.dialogRef.close(this.contactEvent);
            })
        }
    }

    onChangeContact() {
        this.contactEvent.contact = null;
        this.currentStep = 'contact-selection';

        localStorage.removeItem('p2-token');
    }

    saveData(): Observable<ContactEvent[]> {
        this.isLoading = true;

        // Backfill
        this.contactEvent.contactGuid = this.contactEvent.contact.guid;
        this.contactEvent.eventGuid = this.contactEvent.event.guid;

        let tasks: Observable<any>[] = [];

        tasks.push(this.processContactEvent());

        if (this.isVolunteer()) {
            tasks.push(this.processOpportunities());
        }

        // Add contact event record for each child
        if (this.contactEvent.contact?.children?.length > 0 && !this.contactEvent.event.isParentsOnly && this.isClient()) {
            _.each(this.contactEvent.contact.children, child => {
                tasks.push(this.processChildContactEvent(child));
            })
        }

        return forkJoin(tasks).pipe(take(1), map(res => {
            this.isLoading = false;

            return res;
        }));
    }

    processOpportunities(): Observable<EventVolunteerOpportunity[]> {
        var tasks: Observable<EventVolunteerOpportunity>[] = [];

        _.each(this.opportunities, o => {
            if (o.isSelected && !o.assignedVolunteerGuid) {
                tasks.push(this.eventService.assignEventVolunteerOpportunity(this.event.guid, o.guid, this.contact.guid));
            }
            else if (!o.isSelected && (o.assignedVolunteerGuid == this.contact.guid)) {
                tasks.push(this.eventService.unassignEventVolunteerOpportunity(this.event.guid, o.guid, this.contact.guid));
            }
        });
        
        return tasks.length > 0 ? forkJoin(tasks).pipe(take(1)) : of([]);
    }

    processContactEvent(): Observable<ContactEvent> {
        return this.eventService.registerForEvent(this.contactEvent.event.guid, this.contactEvent.contact.guid);
    }

    processChildContactEvent(child: Contact): Observable<ContactEvent> {
        let parentId = this.contactEvent.contact.guid;

        if (child.isSelected) {
            return this.eventService.registerChildForEvent(this.event.guid, parentId, child.guid);
        }
        else {
            return this.eventService.cancelChildEventRegistration(this.event.guid, parentId, child.guid);
        }
    }
}
