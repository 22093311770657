import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[maxDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxDateValidatorDirective,
      multi: true
    }
  ]
})
export class MaxDateValidatorDirective implements Validator {
  @Input('maxDate') maxDate!: Date;

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null; // No value entered, so it's valid
    }

    const inputDate = new Date(control.value);
    const max = this.maxDate;

    if (!max || isNaN(inputDate.getTime()) || isNaN(max.getTime())) {
      return null; // Invalid date format, let other validators handle it
    }

    return inputDate > max ? { maxDate: true } : null;
  }
}
