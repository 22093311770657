import { Component, OnInit, Input, EventEmitter, Output, Inject } from '@angular/core';
import * as _ from 'lodash';
import { Event } from 'src/app/_models/event';
import * as dayjs from 'dayjs';
import { Dialog } from '@angular/cdk/dialog';
import { EditChildDialogComponent } from '../edit-child-dialog/edit-child-dialog.component';
import { EventVolunteerOpportunity } from 'src/app/_models/event-volunteer-opportunity';
import { ContactService } from 'src/app/_services/contact.service';
import { EventService } from 'src/app/_services/event.service';

@Component({
    selector: 'opportunity-selection',
    templateUrl: './opportunity-selection.component.html',
    styleUrls: ['./opportunity-selection.scss']
})
export class OpportunitySelectionComponent implements OnInit {
    @Input() event: Event;
    @Input() isFormValid: boolean = false;
    @Input() opportunities: EventVolunteerOpportunity[] = [];

    @Output() isFormValidChange = new EventEmitter<boolean>();
    @Output() opportunitiesChange = new EventEmitter<EventVolunteerOpportunity[]>();

    isLoading: boolean = false;
    currentUserGuid: string;

    constructor(public dialog: Dialog, private eventService: EventService, private contactService: ContactService) {
    }

    ngOnInit() {
        this.currentUserGuid = this.contactService.getCurrentUserGuid();
    }
    
    onSelectOpportunity(opportunity: EventVolunteerOpportunity) {
        if (!opportunity.assignedVolunteerGuid) {
            opportunity.isSelected = true;
            
            this.opportunitiesChange.emit(this.opportunities);
        }
    }

    onUnselectOpportunity(opportunity: EventVolunteerOpportunity) {
        opportunity.isSelected = false;
        
        this.opportunitiesChange.emit(this.opportunities);
    }
}
