<div class="flex-column tight">
    <h5 class="w-full">Opportunities</h5>

    <table class="p2-list">
        <tbody>
            <ng-container *ngFor="let opportunity of opportunities" >
                <tr>
                    <td>
                        <div class="card flex-column tight col-12 mb-0" [class.selected]="opportunity.isSelected">
                            <div class="flex-row tighter row-one responsive">
                                <h6 class="flex-1">{{opportunity.name}}</h6>

                                <div class="flex-row tight responsive">
                                    @if (opportunity.isSelected) {
                                        <span class="">Assigned to you</span>
                                        <button class="secondary" (click)="onUnselectOpportunity(opportunity)"><i class="fas fa-times"></i> Remove me</button>
                                    }
                                    @else {
                                        @if (!!opportunity.assignedVolunteer && opportunity.assignedVolunteer.guid != currentUserGuid) {
                                            <span class="">Assigned to {{ opportunity.assignedVolunteer.firstName }} {{ opportunity.assignedVolunteer.lastName }}</span>
                                        }
                                        @else {
                                            <button class="light-blue" (click)="onSelectOpportunity(opportunity)">Select</button>
                                        }
                                        
                                    }
                                </div>
                            </div>

                            <p class="secondary" *ngIf="!!opportunity.description">{{ opportunity.description }}</p>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
