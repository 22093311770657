import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { Contact } from 'src/app/_models/contact';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { ChangePhoneDialogComponent, ChangePhoneDialogData } from '../change-phone-dialog/change-phone-dialog.component';
import * as dayjs from 'dayjs';

@Component({
    selector: 'contact-basic-info-form',
    templateUrl: './contact-basic-info-form.component.html',
    styleUrls: ['./contact-basic-info-form.scss']
})
export class ContactBasicInfoFormComponent implements OnInit {
    @ViewChild('myForm', { static: true }) myForm: NgForm;

    @Input() contact: Contact = {} as Contact;
    @Input() hideEmailPhone: boolean = false;
    @Input() lockPhone: boolean = false;
    @Input() isFormValid: boolean = false;
    @Input() isChild: boolean = false;

    @Output() contactChange = new EventEmitter<Contact>();
    @Output() isFormValidChange = new EventEmitter<boolean>();

    isLoading: boolean = false;
    subscription: Subscription;
    
    allGenders: any[] = [
        { name: 'Unspecified', value: undefined },
        { name: 'Female', value: 'F' },
        { name: 'Male', value: 'M' }
    ];

    constructor(public dialog: Dialog) {
    }

    ngOnInit() {
        this.subscription = this.myForm.statusChanges
        .subscribe(() => {
            this.isFormValid = !this.myForm.form.invalid;
            this.isFormValidChange.emit(this.isFormValid);
        })
    }

    ngOnDestroy() {
        if (!!this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onChangePhoneNumber() {
        const dialogRef = this.dialog.open<ChangePhoneDialogData>(ChangePhoneDialogComponent, {
            data: { contact: this.contact }
        });

        dialogRef.closed.subscribe(result => {
            if (!!result?.contact) {
                this.contact = result.contact;
                this.contactChange.emit(this.contact);
            }
        });
    
    }

    getMaxDob(): Date {
        return this.isChild ? null : dayjs().subtract(13, 'year').toDate();
    }
}
